<template>
<b-row class="btb_license_input_label">
        <!-- general information section -->
        <b-col sm="12">
            <div class="group-form-card">
                <b-card>
                    <b-form-group label-cols-lg="3" :label="$t('teaGarden.application_info')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                        <b-row>
                            <!-- application type -->
                            <b-col sm="6">
                                <!-- {{ search.application_type }} -->
                                <ValidationProvider name="Application Type" vid="application_type" :rules="{required: true, min_value: 1}">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="application_type">
                                        <template v-slot:label>
                                            {{ $t('teaGardenPanel.application_type') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            :disabled="isRenew"
                                            plain
                                            v-model="search.application_type"
                                            id="application_type"
                                            :options="applicationType"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @change="updateFormData"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <!-- search by registration no when create renew -->
                            <b-col sm="6" v-if="search.application_type === 2">
                                <div class="row">
                                    <b-col sm="8">
                                        <ValidationProvider name="Registration No" vid="registration_no" :rules="{required: true}">
                                            <b-form-group
                                                slot-scope="{ valid, errors }"
                                                label-for="registration_no">
                                                <template v-slot:label>
                                                    {{ $t('teaGardenPanel.registration_no') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    type="text"
                                                    v-model="search.registration_no"
                                                    @change="updateFormData"
                                                    :state="errors[0] ? false : (valid ? true : null)">
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="4">
                                        <b-button type="button" @click="checkApplication()" variant="success" class="mt-3 btn-sm">{{ $t('globalTrans.search') }} <b-spinner v-if="isLoading" variant="danger" small label="Spinning"></b-spinner></b-button>
                                    </b-col>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-card>
            </div>
        </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { mapGetters } from 'vuex'
export default {
    props: ['searchFormData', 'isRenew', 'service_id'],
    components: {
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        search: {
            application_type: 0,
            registration_no: ''
        },
        isLoading: false
      }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        currentLocale () {
            return this.$i18n.locale
        },
        applicationType () {
          return [
            { value: 1, text: this.$i18n.locale === 'bn' ? 'নতুন নিবন্ধন' : 'New Registration' },
            { value: 2, text: this.$i18n.locale === 'bn' ? 'নবায়ন' : 'Renew' }
          ]
        }
    },
    methods: {
       async checkApplication () {
            if (this.search.registration_no) {
                let result = ''
                this.isLoading = true
                let url = ''
                if (this.service_id === 1) {
                    url = '/tea-garden-panel/tea-garden-application/garden-info/show-item'
                } else if (this.service_id === 3) {
                    url = '/tea-garden-panel/small-tea-farmer-application/show-item'
                } else if (this.service_id === 4) {
                    url = '/tea-garden-panel/tea-factory-application/show-item'
                }
                result = await RestApi.getData(teaGardenServiceBaseUrl, url, this.search)

                if (result.success) {
                    const data = Object.assign(result.data, {
                        application_type: this.search.application_type,
                        registration_no: this.search.registration_no,
                        id: 0,
                        parent_id: [3, 4].includes(this.service_id) ? result.data.id : result.data.application.id
                    })
                    this.$emit('update-form-data', data)
                }

                this.isLoading = false
            }
       },
       updateFormData () {
           const formData = Object.assign(this.searchFormData, { application_type: this.search.application_type, registration_no: this.search.registration_no })
           this.$emit('update-form-data', formData)
       }
    },
    watch: {
    },
    mounted () {
        this.search = Object.assign({}, this.search, { application_type: this.searchFormData.application_type, registration_no: this.searchFormData.registration_no })
    }
}
</script>

<style>
.font-bn .btb_license_input_label .custom-file-label {
  font-size: 14px;
  color: #7c7c7c;
}
.validation_msg {
  font-size: 11px;
}
</style>
